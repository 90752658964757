import React, { useState, useEffect } from 'react';
import '../styles/CookieConsentBar.css';

const CookieConsentBar = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        checkConsent();
    }, []);

    const checkConsent = () => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setVisible(true);
        }
    };

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setVisible(false);
    };

    if (!visible) return null;

    return (
        <div className="cookie-consent-bar">
            <p>Este sitio utiliza cookies para mejorar su experiencia. Al continuar navegando, acepta nuestro uso de cookies.</p>
            <button onClick={handleAccept}>Aceptar</button>
        </div>
    );
};

export default CookieConsentBar;
