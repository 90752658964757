import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/Preinscripciones.css';

const Preinscripciones = () => {
  return (
    <>
      <Helmet>
        <meta name="description" content="Convocatoria de preinscripciones para preescolar, primaria y secundaria en Baja California Sur." />
        <meta property="og:title" content="Convocatoria de Preinscripciones" />
        <meta property="og:description" content="Convocatoria de preinscripciones para preescolar, primaria y secundaria en Baja California Sur." />
        <meta property="og:image" content="https://primarturogro.edu.mx/uploads/LogoArturo.png" />
      </Helmet>
      <div className="preinscripciones-container">
        <h1>📢 Convocatoria de Preinscripciones</h1>
        <p className="text">
          El Gobierno del Estado de Baja California Sur, a través de la Secretaría de Educación Pública, de conformidad con el calendario escolar vigente, convoca a madres, padres de familia o tutores del <strong>04 al 14 de febrero de 2025</strong>, a realizar las preinscripciones de preescolar, primaria y secundaria.
        </p>
        <p className="text">
          El Sistema Educativo Estatal garantiza el acceso a la educación preescolar, a primer grado de primaria, primer grado de secundaria y educación especial en todos sus grados a todas las niñas, los niños, los adolescentes y los jóvenes en edad de cursar estos grados escolares considerando lo establecido en la Ley General de Educación, última versión publicada en el DOF 07-06-2024 en la modificación al Artículo 128.
        </p>
        <h2>📋 Requisitos para la preinscripción en línea</h2>
        <ol className="text">
          <li>Comprobante de domicilio particular o constancia laboral</li>
          <li>CURP del estudiante</li>
          <li>Para la inscripción a primaria, la edad mínima deberá ser de 6 años cumplidos al 31 de diciembre de 2025.</li>
        </ol>
        <h2>📅 Fechas Importantes</h2>
        <p className="text">
          Las fechas de preinscripción son:
        </p>
        <p className="text">
          <strong>Del 04 al 14 de febrero</strong> para ingresar a la plataforma.
        </p>
        <p className="text">
          <strong>Del 10 al 24 de febrero</strong> para llevar la documentación física con cita.
        </p>
        <p className="text">
          <strong>31 de marzo</strong> para consultar los resultados.
        </p>
        <h2>📝 Proceso de Preinscripción</h2>
        <ol className="text">
          <li>
            Del <strong>04 al 14 de febrero</strong>, la Autoridad Educativa recibirá la solicitud de preinscripción a través de la página web oficial de la SEP Estatal:
          </li>
            <a href="https://planeacionbasica.sepbcs.gob.mx/preinscripciones/" target="_blank" rel="noopener noreferrer">
              <button className="btn btn-primary btn-large">Haz clic aquí para entrar a la plataforma</button>
            </a>
          <li>
            La Autoridad Educativa expedirá un comprobante de preinscripción con un número de folio y una cita para acudir al plantel elegido del <strong>10 al 24 de febrero</strong>.
          </li>
          <li>
            A partir del <strong>31 de marzo</strong>, madres, padres o tutores podrán consultar los resultados en la página web oficial de la SEP Estatal.
          </li>
          <li>
            Los seleccionados deberán presentarse al plantel en un lapso de 10 días hábiles a partir del inicio del ciclo escolar 2025-2026.
          </li>
          <li>
            En caso de no ser seleccionados, se les sugerirá alternativas de escuelas y turnos.
          </li>
        </ol>
        <h2>❗ Importante</h2>
        <p className="text">
          Es importante tener en cuenta que las preinscripciones no son para adelantar una preinscripción a estudiantes actualmente inscritos a la institución. Esta convocatoria no está dirigida para alumnos que en el Ciclo Escolar 2025-2026 pasarán a 2do, 3er, 4to, 5to o 6to grado.
        </p>
        <h2>📞 Contacto</h2>
        <p className="text">
          Para más información, pueden comunicarse a los siguientes contactos:
        </p>
        <ul className="text">
          <li>Preescolar: preescolar@sepbcs.gob.mx, Tel: 612 123 81 17</li>
          <li>Primaria: primarias@sepbcs.gob.mx, Tel: 612 123 81 30</li>
          <li>Secundaria: preinscripcionsecundarias@sepbcs.gob.mx, Tel: 612 123 80 67</li>
          <li>Educación Especial: educacion.especial@sepbcs.gob.mx, Tel: 612 123 81 16</li>
        </ul>
      </div>
    </>
  );
};

export default Preinscripciones;